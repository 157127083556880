import React from 'react';
import styled from 'styled-components';

import Nimg1 from 'imgs/ty/n1.png';
import Nimg2 from 'imgs/ty/n2.png';
import Nimg3 from 'imgs/ty/n3.png';
import Num1 from 'imgs/ty/num1.png';
import Num2 from 'imgs/ty/num2.png';
import Num3 from 'imgs/ty/num3.png';
import AsideImage from 'imgs/ty/l1.png';
import CImg from 'imgs/ty/l2.png';
import DapImg from 'imgs/ty/l22.png';
// import News from 'RootCommon/ty-news';
import AImage1 from 'imgs/ty/l31.png';
import AImage2 from 'imgs/ty/l32.png';
import AImage3 from 'imgs/ty/l33.png';
import Ic1 from 'imgs/ty/l34.png';
import Ic2 from 'imgs/ty/l35.png';
import Ic3 from 'imgs/ty/l36.png';
import ST1Img from 'imgs/ty/l23.png';
import ST2Img from 'imgs/ty/l24.png';
import ST3Img from 'imgs/ty/l25.png';

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  .point1 {
    text-align: center;
    font-size: 1.5rem;
    width: 98px;
    height: 60px;
    line-height: 60px;
    color: #4cb896;
    background-image: url(${Nimg1});
  }
  .point2 {
    margin-top: 3rem;
    text-align: center;
    font-size: 1.5rem;
    width: 98px;
    height: 60px;
    line-height: 60px;
    color: #4cb896;
    background-image: url(${Nimg2});
  }
  .point3 {
    clear: both;
    margin: 3.5rem auto 0;
    text-align: center;
    font-size: 1.5rem;
    width: 98px;
    height: 60px;
    line-height: 60px;
    color: #4cb896;
    background-image: url(${Nimg3});
  }
  .content {
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 2.5rem 0.4rem 0 0.4rem;

    .aside {
      width: 20rem;
      margin-right: 1.8rem;
    }
    .intro {
      position: relative;
      width: 27.9rem;
      .sub-title {
        font-size: 1rem;
        color: #4cb896;
        padding: 1.2rem 0 0.5rem;
      }
      .sub-icon {
        height: 3.75rem;
        display: flex;
        align-item: center;
        padding-bottom: 1rem;
      }
      > p {
        line-height: 1.8rem;
        font-size: 0.8rem;
        margin-bottom: 0.8rem;
      }
      .sub-iconp {
        padding: 0.2rem 0 0 0.85rem;
        color: #4cb896;
        font-size: 20px;
      }
      .sub-ictxt {
        text-align: justify;
      }
    }
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      .intro {
        margin: 2rem 0 5rem 0;
      }
      .aside {
        width: 100%;
        margin-right: 0;
        transform: scale(1.3);
      }
    }
  }
  .sub-content {
    text-align: center;
    line-height: 2.5;
    color: #424242;
    font-size: 0.8rem;
    .sub-title2 {
      font-size: 1rem;
      color: #4cb896;
      padding: 2rem 0 0rem;
    }
    .sub-cp {
      padding: 1.5rem 0;
    }
    .sub-dap {
      color: #4bae8d;
      font-size: 18px;
      font-weight: bold;
      width: 241px;
      height: 21px;
      line-height: 21px;
      margin: 2rem auto;
      background-image: url(${DapImg});
    }
    .cards {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 0rem;
      z-index: 999;
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
      .card {
        position: relative;
        border-radius: 2rem;
        width: 17.9rem;
        height: 16.5rem;
        overflow: hidden;
        box-shadow: 0px 8px 15px 1px rgba(56, 0, 95, 0.05);
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 0.6rem;
        }
        @media (max-width: 768px) {
          margin-bottom: 2rem;
          &:not(:last-child) {
            margin-right: 0;
          }
        }
        .cover {
          border-radius: 2rem;
          width: 100%;
        }
        .title {
          width: 17.9rem;
          height: 1.8rem;
          line-height: 1.8rem;
          position: absolute;
          left: 0;
          bottom: 0;
          font-size: 0.8rem;
          text-align: center;
          background: #4bb794;
          color: #ffffff;
        }
        &:hover {
          .title {
            opacity: 0;
          }
          .mask {
            opacity: 1;
          }
          .mask2 {
            opacity: 1;
          }
          .mask3 {
            opacity: 1;
          }
        }
        .mask {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 4rem 1rem 0;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          background-image: url(${Num1});
          width: 100%;
          height: 100%;
          border-radius: 28px;
          transition: all 0.8s;
          .head {
            text-align: left;
            font-size: 0.8rem;
            color: #4cb896;
            line-height: 2;
          }
          .desc {
            text-align: left;
            color: #424242;
            font-size: 0.8rem;
            line-height: 2;
            overflow: hidden;
            > p {
              color: #fff;
              margin-bottom: 1.2rem;
            }
          }
        }
        .mask2 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 4rem 1rem 0;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          background-image: url(${Num2});
          width: 100%;
          height: 100%;
          border-radius: 28px;
          transition: all 0.8s;
          .head {
            text-align: left;
            font-size: 0.8rem;
            color: #4cb896;
            line-height: 2;
          }
          .desc {
            text-align: left;
            color: #424242;
            font-size: 0.8rem;
            line-height: 2;
            overflow: hidden;
            > p {
              color: #fff;
              margin-bottom: 1.2rem;
            }
          }
        }
        .mask3 {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          padding: 4rem 1rem 0;
          opacity: 0;
          position: absolute;
          top: 0;
          left: 0;
          background-image: url(${Num3});
          width: 100%;
          height: 100%;
          border-radius: 28px;
          transition: all 0.8s;
          .head {
            text-align: left;
            font-size: 0.8rem;
            color: #4cb896;
            line-height: 2;
          }
          .desc {
            text-align: left;
            color: #424242;
            font-size: 0.8rem;
            line-height: 2;
            overflow: hidden;
            > p {
              color: #fff;
              margin-bottom: 1.2rem;
            }
          }
        }
      }
    }
  }
`;

const Introp = () => {
  return (
    <Wrapper>
      <div className="point1">亮点一</div>
      <div className="content">
        <img className="aside" src={AsideImage} alt="" />

        <div className="intro">
          <div className="sub-title">能帮助民办幼儿园深度解决“托育招生难”问题</div>
          <p>
            “点点未来”历经1年多，投入强大的人力、物力、财力成功研发“四步阶梯招生法”。该“四步阶梯招生法”最大的特点就是一切遵循客户思维，从极限获客、到亲子活动、再到半日托、再到全日托，循序渐进，简单可落地，能对招生结果负责。可以预料，招生强，将成为北京点点未来托育联盟的名片。
          </p>
        </div>
      </div>
      <div className="point2">亮点二</div>
      <div className="sub-content">
        <div className="sub-title2">能帮助民办幼儿园深度解决“无专业托育课程”问题</div>
        <p>
          北京点点未来托育联盟推出的课程，
          <br />
          一切围绕孩子在六大领域，即粗大动作、精细动作、社会情绪、语言沟通、认知探索、生活自理的发展做文章。
        </p>
        <p className="sub-cp">
          <img src={CImg} alt="点点未来智慧托育" />
        </p>
        <p>
          我们课程的名称叫“0-3岁婴幼儿发展适宜性课程”，简称“DAP课程”（Developmentally Appropriate
          Practice）。
        </p>
        <div className="sub-dap">DAP课程有三大追求</div>
        <div className="cards">
          <div className="card">
            <img src={ST1Img} alt="" className="cover" />
            <p className="title">DAP课程特别强调游戏的重要性</p>
            <div className="mask">
              <h2 className="head">DAP课程特别强调游戏的重要性</h2>
              <div className="desc">
                我们认为，游戏是促进婴幼儿在所有领域获得适宜性发展的手段。所以，在DAP课程中，我们融入了哒哒宝盒游戏和感统游戏。
              </div>
            </div>
          </div>
          <div className="card">
            <img src={ST2Img} alt="" className="cover" />
            <p className="title">DAP课程特别强调关系的建立</p>
            <div className="mask2">
              <h2 className="head">DAP课程特别强调关系的建立</h2>
              <div className="desc">
                我们认为，实施回应性照护有助于建立一线老师和婴幼儿的亲密关系，让孩子有安全感、归属感、胜任感。
              </div>
            </div>
          </div>
          <div className="card">
            <img src={ST3Img} alt="" className="cover" />
            <p className="title">DAP课程特别强调测评</p>
            <div className="mask3">
              <h2 className="head">DAP课程特别强调测评</h2>
              <div className="desc">
                因为只有测评，才能了解孩子已有的知识和能力；因为只有了解孩子已有的知识和能力，我们才能给到适应性的教学；因为只有我们给到适应性的教学，我们才能抵达我们的课程目标。
              </div>
            </div>
          </div>
        </div>
        <div className="point3">亮点三</div>
        <div className="sub-content">
          <div className="sub-title2">能帮助民办幼儿园深度解决“托育交付难”问题</div>
        </div>
        <div className="content" style={{ paddingTop: '1rem' }}>
          <img className="aside" src={AImage1} alt="" />
          <div className="intro">
            <div className="sub-icon">
              <img src={Ic1} alt="" />
              <div className="sub-iconp">十大场景的交付</div>
            </div>
            <p className="sub-ictxt">
              考勤、体温、进餐、入睡、喝水、喝奶、服药、拉臭臭、换纸尿裤、缺纸尿裤，这十大场景的老师照护，通过智能硬件，家长第一时间知道。
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: '1.4rem' }}>
          <img className="aside" src={AImage2} alt="" />

          <div className="intro">
            <div className="sub-icon">
              <img src={Ic2} alt="" />
              <div className="sub-iconp">精彩活动的交付</div>
            </div>
            <p className="sub-ictxt">
              我们开设的新生家长会、升班仪式、生日会、毕业仪式、节庆主题活动以及每周亲子游戏、每月家长讲座、宝宝美食汇等活动，拉进我们和家长之间的距离，增强家长对我们的信任。
            </p>
          </div>
        </div>

        <div className="content" style={{ paddingTop: '1.4rem' }}>
          <img className="aside" src={AImage3} alt="" />
          <div className="intro">
            <div className="sub-icon">
              <img src={Ic3} alt="" />
              <div className="sub-iconp">专业测评的交付</div>
            </div>
            <p className="sub-ictxt">
              我们围绕孩子所做的先天气质测评、0-3岁婴幼儿发展测评和课程配套测评将为我们更好地了解孩子以及提供适宜性发展课程奠定基础。
            </p>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Introp;
