import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Introp from './_Introp';

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 3.6rem 0 5.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Point = () => {
  return (
    <Section id="point" bgc="#f5f5f5">
      <Wrapper>
        <Introp />
      </Wrapper>
    </Section>
  );
};

export default Point;
