import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';

import AsideImage from 'imgs/ty/sys.png';

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sys {
    .tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2.3rem;
      margin-bottom: 2.9rem;
      > p {
        color: #424242;
        font-size: 0.8rem;
        line-height: 1.6rem;
      }
    }
    .sys {
      padding: 2.7rem 0;
    }
  }
`;

const AIBox = () => {
  return (
    <Section id={'ai_box'}>
      <Wrapper>
        <Title text={'“点点未来”智慧托育系统'} />
        <div className="sys">
          <div className="tip">
            <p>“点点未来”智慧托育互联网系统，基于自主研发的强大的物联网和智能硬件，</p>

            <p>
              从以下十个方面来守护婴幼儿健康，让宝宝一日生活可视化，让家园沟通更实时，用科技守护婴幼儿健康成长。
            </p>
            <p>
              <img src={AsideImage} alt="智能盒子配图" className="sys" />
            </p>
            <p>除了以上十大功能外，点点未来智慧托育系统还可以根据当日、一周和每月的数据，</p>
            <p>
              给家长发送婴幼儿在园的行为日报、周报和月报，帮助家长更清晰的了解孩子每一个成长阶段的进步和发展情况。
            </p>
          </div>
        </div>
      </Wrapper>
    </Section>
  );
};

export default AIBox;
