import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';

import ImgLogos from 'imgs/ty/logos.png';

const Wrapper = styled.div`
  position: relative;
  max-width: 40rem;
  margin: 0 auto;
  padding: 4rem 0 5.2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .logos {
    margin-top: 4rem;
    width: 46rem;
  }
  @media (max-width: 768px) {
    .logos {
      width: 90%;
    }
  }
`;

const Cooperation = () => {
  return (
    <Section id={'coop'}>
      <Wrapper>
        <Title text={'“点点未来” 合作机构'} />

        <img src={ImgLogos} alt="合作伙伴" className="logos" />
      </Wrapper>
    </Section>
  );
};

export default Cooperation;
