import React from 'react';
import styled from 'styled-components';
import IcImage1 from 'imgs/ty/ic1.png';
import IcImage2 from 'imgs/ty/ic2.png';
const StyledWrapper = styled.header`
  font-size: 1.7rem;
  margin: 0;
  padding: 0;
  position: relative;
  color: #4cb896;
  .widget {
    position: absolute;
    top: 0;
    width: 42px;
    height: 28px;
    background-repeat: no-repeat;
    &.left {
      left: -4rem;
      background-image: url(${IcImage1});
    }
    &.right {
      right: -4rem;
      background-image: url(${IcImage2});
    }
  }
`;
export default function Title({ text = '默认标题' }) {
  return (
    <StyledWrapper>
      <div className="widget left" />
      {text}
      <div className="widget right" />
    </StyledWrapper>
  );
}
