import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';

import BrandImage from 'imgs/ty/brand.png';
import BGImage from 'imgs/ty/brand-bg.png';

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 4rem 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .sys {
    .tip {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2.3rem;
      margin-bottom: 2.9rem;
      > p {
        color: #424242;
        font-size: 0.8rem;
        line-height: 1.6rem;
      }
    }
    .sys {
      padding: 2.7rem 0;
    }
  }
`;

const AIBox = () => {
  return (
    <Section id={'brand'} bgImage={BGImage}>
      <Wrapper>
        <Title text={'“点点未来”品牌荣誉'} />
        <div className="sys">
          <div className="tip">
            <p>
              <img src={BrandImage} alt="“点点未来”品牌荣誉" className="sys" />
            </p>
          </div>
        </div>
      </Wrapper>
    </Section>
  );
};

export default AIBox;
