import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
// import ShowImg from 'imgs/ty/show.png';

import 'swiper/css/swiper.min.css';
import Swiper from 'swiper';

import Slide1 from 'imgs/ty/show1.png';
import Slide2 from 'imgs/ty/show2.png';
import Slide3 from 'imgs/ty/show3.png';

import ArrowPrev from 'imgs/ty/prev.png';
import ArrowNext from 'imgs/ty/next.png';

const Wrapper = styled.div`
  max-width: 48rem;
  margin: 0 auto;
  padding: 4rem 0 5.2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .showp {
    position: relative;
    margin: 4rem auto 0 auto;
    width: 48rem;
    height: 26.8rem;
    .arrowPrev {
      z-index: 999;
      position: absolute;
      top: 12rem;
      left: -3.6rem;
      width: 1.4rem;
      height: 2.6rem;
      cursor: pointer;
    }
    .arrowNext {
      z-index: 999;
      position: absolute;
      top: 12rem;
      right: -3.6rem;
      width: 1.4rem;
      height: 2.6rem;
      cursor: pointer;
    }
    }
    .swiper {
      width: 48rem;
      height: 26.8rem;
      overflow: hidden;
      position: relative;
      .swiper-pagination {
        bottom: 18px;
      }
      .swiper-wrapper {
        width: 48rem;
        height: 26.8rem;
        margin: 0 auto;
      }
      .swiper-slide {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center center;
        &.slide1 {
          background-image: url(${Slide1});
          background-size: cover;
        }
        &.slide2 {
          background-image: url(${Slide2});
          background-size: cover;
        }
        &.slide3 {
          background-image: url(${Slide3});
          background-size: cover;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .showp {
      width: 90%;
    }
  }
`;

const Show = () => {
  const container2 = useRef(null);
  const nextEl = useRef(null);
  const prevEl = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (container2) {
        new Swiper(container2.current, {
          keyboard: false,
          mousewheel: false,
          loop: true,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          },
          effect: 'fade',
          navigation: {
            prevEl: prevEl.current,
            nextEl: nextEl.current
          }
        });
      }
    }, 300);
  }, []);
  return (
    <Section id={'show'} bgc="#f5f5f5">
      <Wrapper>
        <Title text={'“点点未来” 环境展示'} />
        {/* <img src={ShowImg} alt="环境展示" className="showp" /> */}
        <div className="showp">
          <img ref={prevEl} src={ArrowPrev} alt="arrow left" className="arrowPrev" />
          <img ref={nextEl} src={ArrowNext} alt="arrow right" className="arrowNext" />
          <div ref={container2} className="swiper">
            <div className="swiper-wrapper">
              <div className="swiper-slide slide1"></div>
              <div className="swiper-slide slide2"></div>
              <div className="swiper-slide slide3"></div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Section>
  );
};

export default Show;
