/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect } from 'react';
import styled from 'styled-components';
const StyledWrapper = styled.ul`
  position: absolute;
  top: 0;
  right: 5rem;
  padding: 1.6rem 0.5rem 0 0.5rem;
  color: #4cb896;
  z-index: 999;
  display: flex;
  border-bottom-right-radius: 0.6rem;
  border-bottom-left-radius: 0.6rem;
  @media (max-width: 768px) {
    display: none;
  }
  li {
    position: relative;
    .nav {
      display: block;
      font-size: 0.8rem;
      font-weight: 300;
      color: #4cb896;
      margin: 0 0.5rem;
      word-break: keep-all;
      padding-bottom: 1.18rem;
      &.curr {
        font-weight: 800;
        border-bottom: 2px solid #b9d12f;
      }
    }
    &:not(:last-child):after {
      content: '';
      display: block;
      position: absolute;
      top: 1px;
      right: 0;
      background: #4cb896;
      width: 1px;
      height: 0.7rem;
    }
  }
`;
const Links = [
  {
    title: '首   页',
    id: 'banner'
  },
  {
    title: '点点未来简介',
    id: 'intro'
  },
  {
    title: '三大亮点',
    id: 'strategy'
  },
  // {
  //   title: '十大课程',
  //   id: 'course'
  // },
  {
    title: '智慧托育系统',
    id: 'ai_box'
  },
  {
    title: '专家团队',
    id: 'team'
  },
  {
    title: '品牌荣誉',
    id: 'brand'
  },
  {
    title: '环境展示',
    id: 'show'
  },
  {
    title: '加入我们',
    id: 'join'
  }
];
export default function Nav() {
  const handleClick = (evt) => {
    let currLink = evt.target;
    let id = currLink.getAttribute('href');
    let viewEle = document.querySelector(id);
    // 判断下兼容性
    if ('scrollIntoView' in document.body) {
      evt.preventDefault();
      if (viewEle) {
        viewEle.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
      }
      console.log({ currLink }, id);
    }
    [...document.querySelectorAll('#site_nav li>a.nav')].forEach((link) => {
      link.classList.remove('curr');
    });
    currLink.classList.add('curr');
    [...document.querySelectorAll('.block')].forEach((block) => {
      block.classList.remove('target');
    });
    viewEle.classList.add('target');
  };
  useEffect(() => {
    let { hash } = document.location;
    if (hash) {
      let navEle = document.querySelector(hash);
      if (navEle) {
        navEle.classList.add('curr');
      }
    } else {
      let firstLinkEle = document.querySelector('#site_nav li>a:first-child');
      firstLinkEle.classList.add('curr');
    }
  }, []);
  return (
    <StyledWrapper id="site_nav">
      {Links.map(({ title, id }) => {
        return (
          <li key={id}>
            <a onClick={handleClick} className="nav" href={`#${id}`}>
              {title}
            </a>
          </li>
        );
      })}
    </StyledWrapper>
  );
}
