import GFCImg from 'imgs/zj/team/gfc.png';
import ZGJImg from 'imgs/zj/team/zgj.png';
import WWGImg from 'imgs/ty/wwg.png';
import YCMImg from 'imgs/ty/ycm.png';
import LZHImg from 'imgs/ty/lzh.png';
import WHBImg from 'imgs/ty/whb.png';
import ZMXImg from 'imgs/ty/zmx.png';
import DYFImg from 'imgs/ty/dyf.png';
import ZJImg from 'imgs/ty/zj.png';
import LFImg from 'imgs/ty/lf.png';
import LXYImg from 'imgs/ty/lxy.png';
import LLImg from 'imgs/ty/ll.png';

const Profiles = [
  {
    name: '郭福昌',
    avator: GFCImg,
    bgColor: '#767676',
    titles: ['中国民办教育协会', '学前教育专业委员会创始理事长']
  },

  {
    name: '张光鉴',
    avator: ZGJImg,
    bgColor: '#c2bca2',
    titles: ['钱学森弟子、著名思维科学家', '“相似论”创立者']
  },
  {
    name: '王卫国',
    avator: WWGImg,
    bgColor: '#242424',
    titles: ['中国下一代教育基金会理事长']
  },
  {
    name: '杨春茂',
    avator: YCMImg,
    titles: ['中国教师发展基金会原秘书长', '中国教育学会监事长']
  },
  {
    name: '雷振海',
    avator: LZHImg,
    titles: ['中国教育报刊社副社长', '中国教师报总编辑']
  },

  {
    name: '王红兵',
    avator: WHBImg,
    bgColor: '#5d6063',
    titles: ['中国未来研究会托育分会会长', '壹点壹滴创始人、CEO']
  },
  {
    name: '张缪兴',
    avator: ZMXImg,
    bgColor: '#5d6063',
    titles: ['壹点壹滴联合创始人、总裁', '北京大学青年CEO俱乐部理事']
  },
  {
    name: '邓迎锋',
    avator: DYFImg,
    bgColor: '#5d6063',
    titles: ['壹点壹滴创始人、COO', '北京师范大学在职博士班特邀讲师']
  },
  {
    name: '张 静',
    avator: ZJImg,
    titles: ['北京点点未来托育联盟联合创始人', '外研社师资培训资深客座讲师']
  },
  {
    name: '刘 菲',
    avator: LFImg,
    titles: ['北京点点未来托育联盟联合创始人']
  },
  {
    name: '刘晓艳',
    avator: LXYImg,
    titles: ['北京点点未来托育联盟联合创始人', '中国保健协会妇幼协会专家组成员']
  },
  {
    name: '李 莉',
    avator: LLImg,
    titles: ['新浪微博认证知名亲子教育专家', '美国PAT资格认证国际育婴导师']
  }
];

export default Profiles;
