import React from 'react';
import styled from 'styled-components';
import Section from './__Section';
import Title from './__Title';
// import Introp from './_Introp';
// import News from 'RootCommon/news';
const StyledSection = styled(Section)`
  min-height: 5rem;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 96rem;
  margin: 0 auto;
  padding: 3.6rem 0 0rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .cards {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 4.2rem;
    z-index: 999;
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
      }
    }
  }
`;

const Strategy = () => {
  return (
    <StyledSection id="strategy" bgc="#f5f5f5">
      <Wrapper>
        <Title text={'“点点未来”三大亮点'} />
      </Wrapper>
    </StyledSection>
  );
};

export default Strategy;
