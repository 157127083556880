/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Section from './__Section';

import Nav from './__Nav';
import LogoImg from 'imgs/ty/logo.png';
// import BgImage from 'imgs/ty/banner_bg.png';
import 'swiper/css/swiper.min.css';
import Swiper from 'swiper';

import Slide1 from 'imgs/ty/banner_bg.jpg';
import Slide2 from 'imgs/ty/banner_bg2.png';
import Slide3 from 'imgs/ty/banner_bg3.png';
import Slide4 from 'imgs/ty/banner_bg4.png';

const Wrapper = styled.div`
  background: #4cb896;
  padding-top: 3.65rem;
  .top-head {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 99999;
    height: 3.65rem;
    background: #eaf8f5;
  }
  .top-headc {
    position: relative;
    width: 55rem;
    margin: 0 auto;
  }
  .link {
    position: absolute;
    right: 0;
    top: 1.3rem;
  }
  .linka {
    display: block;
    width: 4.5rem;
    height: 1.3rem;
    font-size: 0.7rem;
    border-radius: 0.6rem;
    line-height: 1.3rem;
    text-align: center;
    color: #ffffff;
    background: #4cb896;
  }
  .linka:hover {
    background: #afc72f;
  }
  .logo {
    position: absolute;
    left: 0;
    top: 0.6rem;
    width: 3.1rem;
    @media (max-width: 768px) {
      top: 10px;
      right: 10px;
      left: auto;
    }
  }
  .swiper {
    width: 100%;
    height: 26rem;
    position: relative;
    .swiper-pagination {
      bottom: 18px;
    }
    .swiper-pagination-bullet {
      background: #ffffff;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #b9d12f;
    }
    .swiper-wrapper {
      width: 100%;
      height: 100%;
    }
    .swiper-slide {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
      &.slide1 {
        background-image: url(${Slide1});
        background-size: cover;
      }
      &.slide2 {
        background-image: url(${Slide2});
        background-size: cover;
      }
      &.slide3 {
        background-image: url(${Slide3});
        background-size: cover;
      }
      &.slide4 {
        background-image: url(${Slide4});
        background-size: cover;
      }
    }
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 2;
    .head {
      font-size: 2.2rem;
      font-weight: 800;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        > span {
          line-height: 1.5;
        }
      }
      > span {
        color: #fff;
      }
    }
    .desc {
      background: #e5a537;
      border-radius: 8px;
      color: #9205ab;
      font-size: 1.2rem;
      line-height: 1.3;
      padding: 0.3rem 1rem;
    }
    .list {
      font-size: 0.8rem;
      padding: 1rem 0;
      margin-top: 1rem;
      line-height: 1.8;
      list-style-type: none;
      > li {
        color: #fff;
        position: relative;
        padding-left: 0.8rem;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #e5a537;
        }
      }
      .title_li {
        padding-left: 0;
        &:before {
          display: none;
        }
      }
    }
  }
  .aside {
    z-index: 2;
    width: 14.2rem;
    @media (max-width: 768px) {
      align-self: flex-end;
      margin-top: -6rem;
    }
  }
`;

const Banner = () => {
  const container = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (container) {
        new Swiper(container.current, {
          keyboard: false,
          mousewheel: false,
          loop: true,
          autoplay: true,
          pagination: {
            el: '.swiper-pagination'
          }
        });
      }
    }, 300);
  }, []);
  return (
    // <StyledSection id={'banner'} bgc={'#00b997'}>
    <Section id={'banner'}>
      <Wrapper>
        <div className="top-head">
          <div className="top-headc">
            <Nav />
            {/* <img className="arrows" src={GroupImage} alt="箭头组合" /> */}
            <img src={LogoImg} alt="点点未来智慧托育" className="logo" />
            <div className="link">
              <a className="linka" target="_blank" href={`https://pc.ddfuture.com/`}>
                管理入口
              </a>
            </div>
          </div>
        </div>
        <div ref={container} className="swiper">
          <div className="swiper-wrapper">
            <div className="swiper-slide slide1"></div>
            <div className="swiper-slide slide2"></div>
            <div className="swiper-slide slide3"></div>
            <div className="swiper-slide slide4"></div>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Wrapper>
    </Section>
    // </StyledSection>
  );
};

export default Banner;
