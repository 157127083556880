import React from 'react';
import styled from 'styled-components';
import Profiles from 'RootCommon/ty_teams';

import Section from './__Section';
import Title from './__Title';

const Wrapper = styled.div`
  position: relative;
  max-width: 54rem;
  margin: 0 auto;
  padding: 4rem 0 5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;
    width: 55rem;
    margin-top: 4.9rem;
    .profile {
      background: #ffffff;
      display: flex;
      flex-direction: column;
      width: 13.35rem;
      height: 15.1rem;
      margin-bottom: 1.4rem;
      border-radius: 15px;
      overflow: hidden;
      transition: all 0.8s;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
      }
      .avator {
        width: 100%;
        height: 10.1rem;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      .intro {
        height: 3.85rem;
        padding: 0 0.6rem 0.85rem 0.75rem;
        .name {
          font-size: 0.8rem;
          color: #4caf8d;
          font-weight: 800;
          line-height: 2.2;
        }
        .titles {
          font-size: 0.6rem;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          line-height: 1.5;
          font-weight: 600;
          > span {
            white-space: nowrap;
            color: #636363;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .content {
      width: 30rem;
      .profile {
        width: 14rem;
        height: 18rem;
        .avator {
          height: 11rem;
        }
        .name {
          font-size: 1.2rem;
        }
        .titles {
          font-size: 0.8rem;
        }
      }
    }
  }
`;

const Team = () => {
  return (
    <Section id="team" bgc="#f5f5f5">
      <Wrapper>
        <Title text="“点点未来”专家团队" />

        <div className="content">
          {Profiles.map((p) => (
            <div key={p.name} className="profile">
              <div className="avator" style={{ backgroundColor: p.bgColor || 'none' }}>
                <img src={p.avator} alt="个人头像" />
              </div>
              <div className="intro">
                <h2 className="name">{p.name}</h2>
                <p className="titles">
                  {p.titles.map((t) => (
                    <span key={t}>{t}</span>
                  ))}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Wrapper>
    </Section>
  );
};

export default Team;
